<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <!-- POPUP -->
    <div class>
      <vs-popup
        :id="'popmodalp'+params.data.purchase_id"
        title="Purchase Order Details"
        :active.sync="popupActive"
      >
        <!-- <div :key="imageKey" v-for="(image,imageKey) in images">
          <img :src="image+'?token='+tempToken" alt />
        </div>-->

        <div class="vx-col w-full">
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Purchase Order Name:</p>
                <div class>
                  <h5>{{ projectDetail.po_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Category Name:</p>
                <div class>
                  <h5>{{ projectDetail.category_name }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <!-- <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Subcategory Name:</p>
                <div class>
                  <h5>{{ projectDetail.sub_category_name }}</h5>
                </div>
              </div>
            </div>-->

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Country Name:</p>
                <div class>
                  <h5>{{ projectDetail.country_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Currency</p>
                <div class>
                  <h5>{{projectDetail.currency }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">City Name:</p>
                <div class>
                  <h5>{{ projectDetail.city_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Mode of Payment</p>
                <div v-if="projectDetail.payment_mode != 0" class>
                  <h5>{{ paymentMode(projectDetail.payment_mode) }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Description:</p>
                <div class>
                  <h5>{{ projectDetail.purchase_description }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Amount:</p>
                <div class>
                  <h5>{{ projectDetail.total }}</h5>
                </div>
              </div>
            </div>
            <!-- <div class="mx-0 row mb-6">
              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Currency</p>
                  <div class>
                    <h5>{{projectDetail.currency }}</h5>
                  </div>
                </div>
              </div>
            </div>-->
          </div>

          <!-- <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full text-right align-self-center">
              <h5 class="mb-0">Mode of Payment</h5>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <div class>
                <span>card</span>
              </div>
            </div>
          </div>-->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import companyService from "@/services/companyService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default Vue.extend({
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      images: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
      projectDetail: [],
      tempToken:window.localStorage.getItem("TempToken"),
    };
  },
  watch: {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalp" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  beforeMount() {
    this.projectDetail = this.params.data;
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    paymentMode: function(val) {
      console.log("val :", val);
      if (val == 1) {
        return "Cash";
      } else if (val == 3) {
        return "Bank";
      } else if (val == 2) {
        return "Card";
      }
    },

    // getSinglePurchaseOrder: function() {
    //   let payload = {};
    //   console.log("this.params.valuethis.params.000:::::: :", this.projectID);
    //   purchaseOrderService
    //     .getPurchaseOrder(payload, this.projectID)
    //     .then(response => {
    //       const { data } = response;
    //       if (!data.err) {
    //         this.poDetails = data.data;
    //         console.log("::::::poDetails :", poDetails);
    //       } else {
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    // purchaseOrderList: function() {
    //   let payload = {
    //     limit: this.paginationPageSize
    //   };
    //   purchaseOrderService
    //     .getAllPurchaseOrderByProject(this.projectID, payload)
    //     .then(response => {
    //       const { data } = response;
    //       if (!data.err) {
    //         console.log("data :", data);
    //         this.poOrders = data.data;
    //         this.autoSizeAll();
    //       } else {
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    submitForm() {}
  },

  mounted() {
    // this.purchaseOrderList();
    // this.getSinglePurchaseOrder();
    this.images.push(this.params.value);
  }
});
</script>
<style>
</style>