<!-- =========================================================================================
    File Name: projectPosList.vue
    Description: PURCHASE ORDER LIST
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- PURCHASE ORDER LIST -->

    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap">Filters By</div>
        <div>
          <div class="flex justify-between">
            <vs-input type="text" placeholder="VHC Code" v-model="vhc_code" />
            <multiselect
              label="user_name"
              class="sbwidth"
              track-by="user_id"
              v-model="created_user_id"
              name="Approver"
              :options="BehalfOfList"
              @select="onSelectBehalf"
              :searchable="false"
              :allow-empty="false"
              :select-label="'Select'"
              open-direction="bottom"
              placeholder="Select User"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.user_name
              }}</template>
            </multiselect>

            <multiselect
              label="priority_name"
              class="sbwidth"
              track-by="priority_id"
              v-model="priorityValue"
              name="Priority"
              :options="priority"
              :searchable="false"
              :allow-empty="false"
              :select-label="'Select'"
              open-direction="bottom"
              placeholder="Select Priority"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.priority_name
              }}</template>
            </multiselect>

            <vue-simple-suggest
              :min-length="0"
              v-model="selectedVendor"
              :list="vendorList"
              display-attribute="vendor_name"
              value-attribute="vendor_id"
              :filter-by-query="true"
              name="VendorName"
              placeholder="Select Vendor"
            ></vue-simple-suggest>
            <multiselect
              label="status_name"
              class="sbwidth"
              track-by="status_id"
              v-model="expenseStatus"
              name="Status"
              :options="statuses"
              :searchable="false"
              :allow-empty="false"
              :select-label="'Select'"
              open-direction="bottom"
              placeholder="Select Status"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.status_name
              }}</template>
            </multiselect>
            <!-- <vue-simple-suggest
              :min-length="0"
              v-model="expenseStatus"
              :list="vendorList"
              display-attribute="vendor_name"
              value-attribute="vendor_id"
              :filter-by-query="true"
              name="VendorName"
              placeholder="Select Vendor"
            ></vue-simple-suggest> -->

            <vs-button @click="filterData">Search</vs-button>
            <vs-button @click="clearData">Clear</vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Expense List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <!-- <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export Expense List</vs-button>-->
              <!-- <vx-tooltip text="Export Excel" v-if="showTally==1">
                <a
                  :href="
                    excelurl +
                      `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${
                        this.projectID
                      }&approver_id=0&search=${searchQuery}&tally_isActive=1`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4 mr-2">
                    <span class="d-inline-flex pr-5 text-white">Tally Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip> -->

              <!-- Darshan | 23rd Oct, 2020 -->

              <ul class="list-inline px-4" v-if="isTally == 1">
                <li
                  class="list-inline-item font-semibold project-menu-dropdown"
                >
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <span class="flex cursor-pointer font-semibold">
                      Tally Sync Options : {{ selectedTallyOption }}
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                        class="ml-1"
                      ></feather-icon>
                    </span>
                    <vs-dropdown-menu class="w-32">
                      <vs-dropdown-item
                        selected
                        @click="onSelectTallySyncOption('All')"
                        >All</vs-dropdown-item
                      >
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Approved')"
                        >Approved</vs-dropdown-item
                      >
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Synced')"
                        >Synced</vs-dropdown-item
                      >
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Unsynced')"
                        >Unsynced</vs-dropdown-item
                      >
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </li>
              </ul>

              <!-- Darshan | 23rd Oct, 2020 -->

              <vx-tooltip text="Sync to Tally">
                <vs-button
                  :disabled="
                    selectedTallyOption == 'Unsynced' &&
                    selectedExpenseList.length > 0
                      ? false
                      : true
                  "
                  class="px-4 mr-2"
                  @click.native="syncVendor()"
                >
                  <span class="d-inline-flex pr-5 text-white"
                    >Sync to Tally</span
                  >
                  <!-- <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    /> -->
                </vs-button>
              </vx-tooltip>
              <vx-tooltip text="Export Excel PDF">
                <a
                  :href="
                    excelurl +
                    `/v2/purchase/excel/download?export=pdf&organization_id=${organization_id}&token=${temp_token}&project_id=${this.projectID}&approver_id=0&search=${searchQuery}` +
                    filter_string
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white"
                      >Export PDF</span
                    >
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Excel">
                <a
                  :href="
                    excelurl +
                    `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${this.projectID}&approver_id=0&search=${searchQuery}` +
                    filter_string
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="poOrders"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>

        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import MethodService from "@/services/methodService.js";
import ContractService from "@/services/ContractService.js";

import editPoOrder from "./editPoOrder.vue";
import editPoOrder1 from "./editPoOrder1.vue";
import imagePopUp from "./imagePopUp.vue";
import priority from "./PosPriority.vue";
import expenseDetails from "./expenseDetails.vue";
import amendLogs from "./viewAmendDetails.vue";
import { BASEURL } from "@/config/index.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
const VxTour = () => import("@/components/VxTour.vue");
import multiSelectExpenses from "./multiSelectExpenses.vue";
import UserService from "@/services/UserService.js";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import downloadInvoiceVue from "./downloadInvoice.vue";
import axios from "axios";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    VueSuggest,
    VueSimpleSuggest,
  },
  data() {
    return {
      statuses: [
        { status_name: "Pending", status_id: "pending" },
        { status_name: "Approved", status_id: "approved" },
        { status_name: "Rejected", status_id: "rejected" },
        { status_name: "Held", status_id: "hold" },
      ],
      selectedVendor: null,
      vendorList: [],
      priorityValue: null,
      expenseStatus: null,
      priority: [],
      paymentMode: [],
      BehalfOfList: [],
      created_user_id: null,
      vhc_code: "",
      selectedExpenseList: [],
      showTally: 0,
      totalPages: 0,
      excelurl: BASEURL(),
      poOrders: [],
      User: "Select User",
      popupActive: false,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Approve/Reject",
          field: "purchase_id",
          filter: false,
          width: 175,
          pinned: "left",
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: editPoOrder,
          // cellRenderer: function(params) {
          //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
          // }
        },
        // {
        //   headerName: "Name",
        //   field: "po_name",
        //   width: 200,
        //   filter: true,
        // },
        {
          headerName: "Expense Status",
          field: "purchase_status",
          width: 165,
          filter: true,
          cellRenderer: function (params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            } else if (params.value == 7) {
              return `<div class="font-bold">Held<div>`;
            }
          },
        },
        {
          headerName: "Log",
          field: "log",
          width: 165,
          filter: true,
          cellRendererFramework: editPoOrder1,
        },
        {
          headerName: "Name",
          field: "po_name",
          minWidth: 200,
          filter: true,
        },
        {
          headerName: "User",
          field: "user_name",
          minWidth: 200,
          maxWidth: 300,
          filter: true,
        },
        // {
        //   headerName: "Description",
        //   field: "purchase_description",
        //   filter: true,
        //   width: 275,
        // },
        // {
        //   headerName: "Category",
        //   field: "category_name",
        //   filter: true,
        //   width: 275,
        // },
        // {
        //   headerName: "Sub Category",
        //   field: "sub_category_name",
        //   filter: true,
        //   width: 275,
        // },

        // {
        //   headerName: "City",
        //   field: "city_name",
        //   filter: true,
        //   width: 300,
        // },

        {
          headerName: "Details",
          field: "",
          filter: true,
          width: 150,
          cellRendererFramework: expenseDetails,
        },
        // {
        //   headerName: "Log",
        //   field: "",
        //   filter: true,
        //   width: 150,
        //   cellRendererFramework: amendLogs,
        // },
        {
          headerName: "Amount",
          field: "formattedAmount",
          filter: true,
          width: 150,
        },
        {
          headerName: "TDS Amount",
          field: "tds_amount",
          filter: true,
          width: 150,
          hide: true,
        },
        {
          headerName: "Paid Amount",
          field: "tally_paid_amount",
          filter: true,
          width: 150,
          hide: true,
        },
        // {
        //   headerName: "Currency",
        //   field: "currency",
        //   filter: true,
        //   width: 150
        // },
        // {
        //   headerName: "Mode of Pay",
        //   field: "payment_mode",
        //   filter: true,
        //   width: 145,
        //   cellRenderer: function(params) {
        //     return params.value == 1
        //       ? `Cash`
        //       : params.value == 2
        //       ? `Card`
        //       : `Bank`;
        //   }
        // },
        {
          headerName: "VHC Code",
          field: "VHC_code",
          filter: true,
          width: 150,
        },
        {
          headerName: "Tally Error",
          field: "export_tally_error",
          filter: true,
          width: 150,
          hide: true,
        },
        {
          headerName: "Priority",
          field: "priority",
          filter: false,
          width: 110,
          cellRendererFramework: priority,
        },

        {
          headerName: "Multiple Images",
          field: "purchase_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRendererFramework: imagePopUp,
        },
        {
          headerName: "Download",
          field: "purchase_id",
          filter: true,
          sortable: false,
          width: 140,
          cellRendererFramework: downloadInvoiceVue,
        },
      ],
      contacts: contacts,
      projectID: null,
      organization_id: "",
      selectedTallyOption: "All",
      tallyType: null,
      isTally: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    filter_string() {
      let str = this.vhc_code != "" ? `&vhc_code=${this.vhc_code}` : "";
      str +=
        this.created_user_id != null
          ? `&created_user_id=${this.created_user_id.user_id}`
          : "";
      str +=
        this.priorityValue != null
          ? `&priority=${this.priorityValue.priority_id}`
          : "";
      str +=
        this.expenseStatus != null
          ? `&status=${this.expenseStatus.status_id}`
          : "";
      str +=
        this.selectedVendor != null
          ? `&selectedVendor=${this.selectedVendor}`
          : "";
      return str;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.purchaseOrderList(val);
          return val;
        } else return 1;
      },
    },
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    this.isTally = localStorage.getItem("isTally");

    const { projectID } = this.$route.params;
    this.projectID = projectID || null;
    eventBus.$on("refreshPOList", (e) => {
      this.purchaseOrderList(this.currentPage);
    });
  },
  methods: {
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    syncVendor() {
      this.$vs.loading();
      let seletedExpenseString = "";
      if (this.selectedExpenseList.length > 0) {
        seletedExpenseString = this.selectedExpenseList.toString();
      } else {
        seletedExpenseString = "";
      }

      axios
        .get(
          `${BASEURL}/v2/tally/syncVoucherToTally?organization_id=${this.organization_id}&selectedExpenseList=${seletedExpenseString}`
        )
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    filterData: function () {
      let filters = {
        vhc_code: this.vhc_code && this.vhc_code != "" ? this.vhc_code : "",
        created_user_id: this.created_user_id
          ? this.created_user_id.user_id
          : null,
        priority: this.priorityValue ? this.priorityValue.priority_id : null,
        status: this.expenseStatus ? this.expenseStatus.status_id : null,
        selectedVendor: this.selectedVendor,
      };

      this.purchaseOrderList(1, filters);
    },
    clearData: function () {
      this.vhc_code = "";
      this.created_user_id = null;
      this.priorityValue = null;
      this.expenseStatus = null;
      this.selectedVendor = null;
      this.purchaseOrderList(1);
    },
    onSelectBehalf(selected) {},
    getMapList: function () {
      let payload = {
        project_id: this.projectID,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.BehalfOfList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    purchaseOrderList: function (currentPage, filters = null) {
      this.poOrders = [];
      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: currentPage,
        ...filters,
      };
      console.log("filters", payload);
      purchaseOrderService
        .getAllPurchaseOrderByProject(this.projectID, payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.poOrders = data.data;
            // console.log("Hey", this.poOrders)
            this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
          }
          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.priority = data.data.priority;
            this.paymentMode = data.data.payment_mode;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    onSelectTallySyncOption: function (syncType) {
      this.selectedTallyOption = syncType;

      if (syncType == "Unsynced") {
        this.columnDefs = [
          {
            headerName: "",
            field: "",
            filter: false,
            pinned: "left",
            width: 80,
            suppressSizeToFit: true,
            cellRendererFramework: multiSelectExpenses,
          },

          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          // {
          //   headerName: "Name",
          //   field: "po_name",
          //   width: 200,
          //   filter: true,
          // },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              } else if (params.value == 7) {
                return `<div class="font-bold">Held<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },

          // {
          //   headerName: "City",
          //   field: "city_name",
          //   filter: true,
          //   width: 300,
          // },

          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },
          // {
          //   headerName: "Log",
          //   field: "",
          //   filter: true,
          //   width: 150,
          //   cellRendererFramework: amendLogs,
          // },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      } else if (syncType == "Synced") {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          // {
          //   headerName: "Name",
          //   field: "po_name",
          //   width: 200,
          //   filter: true,
          // },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              } else if (params.value == 7) {
                return `<div class="font-bold">Held<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },

          // {
          //   headerName: "City",
          //   field: "city_name",
          //   filter: true,
          //   width: 300,
          // },

          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },
          // {
          //   headerName: "Log",
          //   field: "",
          //   filter: true,
          //   width: 150,
          //   cellRendererFramework: amendLogs,
          // },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: false,
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      } else {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          // {
          //   headerName: "Name",
          //   field: "po_name",
          //   width: 200,
          //   filter: true,
          // },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              } else if (params.value == 7) {
                return `<div class="font-bold">Held<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },

          // {
          //   headerName: "City",
          //   field: "city_name",
          //   filter: true,
          //   width: 300,
          // },

          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },
          // {
          //   headerName: "Log",
          //   field: "",
          //   filter: true,
          //   width: 150,
          //   cellRendererFramework: amendLogs,
          // },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      }

      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: this.currentPage,
        tallySync: syncType,
      };
      purchaseOrderService
        .getAllPurchaseOrderByProject(this.projectID, payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.poOrders = data.data;
            this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    eventBus.$on("selectedExpense", (e) => {
      let arrIndex = this.selectedExpenseList.indexOf(e);
      if (arrIndex >= 0) {
        this.selectedExpenseList.splice(arrIndex, 1);
      } else {
        this.selectedExpenseList.push(e);
      }
      // this.getWorkTypeList(this.currentPage);
    });
    this.purchaseOrderList(this.currentPage);
    this.getMapList();
    this.getMasterList();
    this.getVendorList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style scoped>
.po-user-card {
  width: 130px;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.sbwidth {
  width: 200px;
}
</style>
