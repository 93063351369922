<template>
  <div>
    <div><span
      class="text-center table-edit-icon"
      @click="getPreview()"
    >
      <i class="text-center material-icons">remove_red_eye</i>
    </span></div>

    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.purchase_id"
        class="holamundo p-2"
        title="Amend Log"
        :active.sync="popupActive"
      >
        <!-- <div class="row mx-0 text-center mt-5 mb-base">
          <div class="col-2">
            <h6>Vendor: </h6>
            <h3 class="text-primary">{{ params.data.po_name }}</h3>
          </div>

          <div class="col-2">
            <h6>Expense Amount: </h6>
            <h3 class="text-primary">{{ params.data.total }}</h3>
          </div>

          <div class="col-2">
            <h6>Expense Amount: </h6>
            <h3 class="text-primary">{{ amendLogs }}</h3>
          </div>
        </div> -->

        <div>
          <!-- <div class="col-12 row">
            <div class="col-10"></div>
            <div class="col-2">
              <vs-input
                class="mb-4 md:mb-0 mr-4 ml-3 "
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div> -->
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="amendLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
          ></ag-grid-vue>
          <!-- :paginationPageSize="paginationPageSize"
            :pagination="true"
          :suppressPaginationPanel="true"-->
          <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import imagePopUp from "../projects/imagePopUp.vue";
import expenseDetails from "./expenseDetailsLog.vue";
// var moment = require("moment");
// moment().format();
export default Vue.extend({
  components: {
    AgGridVue
  },
  data() {
    return {
      params: null,
      expenseDetail: [],
      expenseList: [],
      totalPages: 0,
      popupActive: false,
      gridApi: null,
      searchQuery: "",

      gridOptions: {},
      maxPageNumbers: 7,
      amendLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: []
      // project_id: ""
    };
  },

  beforeMount: function() {
    // console.log(this.params);
    this.columnDefs = [
      {
        headerName: "ReApproved By",
        field: "log_user_name",
        filter: false,
        width: 250,
        // suppressSizeToFit: true
      },
      {
        headerName: "ReApproved Date",
        field: "log_date",
        filter: false,
        width: 250,
        // suppressSizeToFit: true
      },
      {
        headerName: "Expense Details",
        field: "",
        filter: false,
        width: 200,
        // suppressSizeToFit: true
        cellRendererFramework: expenseDetails,
      },
      {
        headerName: "Multiple Images",
        field: "purchase_image",
        filter: true,
        width: 200,
        sortable: false,
        // suppressSizeToFit: true,
        cellRendererFramework: imagePopUp
      }

      // cellRendererFramework: viewBudget
    ];
  },
  mounted() {
    eventBus.$on(
      "showPopupExpense" +
        this.params.data.purchase_id +
        this.params.colDef.headerName,
      e => {
        if (this.params.data.purchase_id == e.purchase_id) {
          console.log(e, "dsfsdf");
          this.popupActive = e.show;
        }
      }
    );

    this.gridApi = this.gridOptions.api;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
//   watch: {
//     "$store.state.windowWidth"(val) {
//       if (val <= 576) {
//         this.maxPageNumbers = 4;
//         this.gridOptions.columnApi.setColumnPinned("email", null);
//       } else this.gridOptions.columnApi.setColumnPinned("email", "left");
//     }
//   },
//   computed: {
//     paginationPageSize() {
//       if (this.gridApi) return this.gridApi.paginationGetPageSize();
//       else return 100;
//     },
//     currentPage: {
//       get() {
//         if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
//         else return 1;
//       },
//       set(val) {
//         if (val) {
//           this.getDepartmentList(val);
//           return val;
//         } else return 1;
//       }
//     }
//   },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getPreview: function() {
      this.popupActive = true;
      this.getAmendLogList();
    },
    getAmendLogList: function() {
      purchaseOrderService.getAmendLogs(this.params.data.purchase_id)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            console.log("Amend Logsssssssssssssssss", data.data);
            this.amendLogs = data.data;
          } else {
            console.log("error :", error);
          }
        })
        .catch(error => {});
    }
  }
});
</script>

<style></style>
