<!-- =========================================================================================
    File Name: projectPosList.vue
    Description: PURCHASE ORDER LIST
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- PURCHASE ORDER LIST -->

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Advance Request List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <!-- <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
                />
                <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export PO List</vs-button>-->

              <!-- <vx-tooltip text="Export Excel" v-if="showTally==1">
                <a
                  :href="
                    excelurl +
                      `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${
                        this.projectID
                      }&approver_id=2&search=${searchQuery}&tally_isActive=1`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4 mr-2">
                    <span class="d-inline-flex pr-5 text-white">Tally Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip> -->
              <!-- <vx-tooltip text="Sync to Tally">
                <vs-button class="px-4 mr-2" @click.native="syncVendor()">
                    <span class="d-inline-flex pr-5 text-white">Sync to Tally</span>

                  </vs-button>
              </vx-tooltip> -->
              <vx-tooltip text="Export Excel">
                <a
                  :href="
                    excelurl +
                    `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${this.projectID}&approver_id=2&search=${searchQuery}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="poOrders"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :floatingFilter="false"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import { BASEURL } from "@/config/index.js";

import editPoOrder from "./editPoOrder.vue";
import imagePopUp from "./imagePopUp.vue";
import priority from "./PosPriority.vue";
import purchaseOrderDetail from "./purchaseOrderDetail.vue";
import challanDetails from "./challanDetails.vue";

import purchaseOrderService from "@/services/purchaseOrderService.js";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import downloadInvoiceVue from "./downloadInvoice.vue";
import axios from "axios";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      showTally: 0,
      excelurl: BASEURL(),
      poOrders: [],
      User: "Select User",
      popupActive: false,
      searchQuery: "",
      overlayNoRowsTemplate: null,
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Approve/Reject",
          field: "purchase_id",
          filter: false,
          width: 175,
          sortable: false,
          pinned: "left",
          cellStyle: { "text-align": "center" },
          cellRendererFramework: editPoOrder,
          // cellRenderer: function(params) {
          //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
          // }
        },
        {
          headerName: "Advance Request Status",
          field: "purchase_status",
          width: 165,
          filter: true,
          cellRenderer: function (params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            } else if (params.value == 7) {
              return `<div class="text-primary font-bold">Held<div>`;
            }
          },
        },
        {
          headerName: "Name",
          field: "po_name",
          minWidth: 200,
          filter: false,
        },
        {
          headerName: "User",
          field: "user_name",
          minWidth: 200,
          maxWidth: 300,
          filter: false,
        },
        // {
        //   headerName: "Description",
        //   field: "purchase_description",
        //   filter: false,
        //   minWidth: 300,
        //   maxWidth: 500,
        //   width: 275,
        // },
        {
          headerName: "Amount",
          field: "formattedAmount",
          filter: false,
          width: 150,
        },
        {
          headerName: "Currency",
          field: "currency",
          filter: false,
          width: 150,
        },

        // {
        //   headerName: "Mode of Payment",
        //   field: "payment_mode",
        //   filter: true,
        //   width: 150,
        //   cellRenderer: function(params) {
        //     return params.value == 1
        //       ? `Cash`
        //       : params.value == 2
        //       ? `Credit Card`
        //       : `Debit Card`;
        //   }
        // },
        {
          headerName: "Priority",
          field: "priority",
          filter: false,
          width: 110,
          cellRendererFramework: priority,
        },
        {
          headerName: "Multiple Images",
          field: "purchase_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRendererFramework: imagePopUp,
        },
        // {
        //   headerName: " Detail",
        //   field: "",
        //   filter: false,
        //   width: 165,
        //   sortable: false,
        //   cellRendererFramework: purchaseOrderDetail
        // },
        // {
        //   headerName: "Challan Detail",
        //   field: "purchase_id",
        //   filter: false,
        //   width: 165,
        //   sortable: false,
        //   cellRendererFramework: challanDetails
        // }

        // {
        //   headerName: "Download",
        //   field: "purchase_id",
        //   filter: false,
        //   width: 125,
        //   sortable: false,

        //   cellRendererFramework: downloadInvoiceVue,
        // },
      ],
      contacts: contacts,
      projectID: null,
      organization_id: "",
      totalPages: 0,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    // paginationPageSize() {
    //   if (this.gridApi) return this.gridApi.paginationGetPageSize();
    //   else return 100;
    // },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.purchaseOrderList(val);
          return val;
        } else return 1;
      },
      // set(val) {
      //   this.gridApi.paginationGoToPage(val - 1);
      // }
    },
  },

  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");

    const { projectID } = this.$route.params;
    this.projectID = projectID || null;
    // this.overlayNoRowsTemplate =
    //   '<span class="ag-overlay-loading-center">No PO to show</span>';

    eventBus.$on("refreshPOList", (e) => {
      this.purchaseOrderList(this.currentPage);
    });
  },
  methods: {
    syncVendor() {
      this.$vs.loading();
      axios
        .get(`${BASEURL}/v2/tally/syncVoucherToTally`)
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    purchaseOrderList: function (currentPage) {
      let payload = {
        type: 2,
        page_no: currentPage,
      };
      purchaseOrderService
        .getAllPurchaseOrderByProject(this.projectID, payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            console.log("data :", data);
            this.poOrders = data.data;
            this.totalPages = data.pagination.last_page;

            this.autoSizeAll();
          } else {
          }
          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
  },
  mounted() {
    this.purchaseOrderList(this.currentPage);
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style scoped>
.po-user-card {
  width: 130px;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.carousel-example .swiper-container .swiper-slide {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background-color: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 300px;
}
</style>
