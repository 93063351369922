<template>
  <div class="center-icon">
    <span
      class="text-center dot-edit-icon"
      :class="priority == '2' ? 'normal' : priority == '1' ? 'high' : 'low'"
    ></span>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import companyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      images: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
      priority: "",
    };
  },
  watch: {},
  beforeMount() {
    console.log("jitu1", this.params);
    this.priority = this.params.data.priority;
  },
  methods: {
    showModal() {
      console.log("object");
      this.popupActive = true;
    },
    submitForm() {},
  },

  mounted() {
    this.images.push(this.params.value);
  },
});
</script>
<style>
.dot-edit-icon {
  height: 24px;
  width: 24px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.dot-edit-icon.low {
  background-color: #ffc107;
}

.dot-edit-icon.high {
  background-color: #dc3545;
}

.dot-edit-icon.normal {
  background-color: #28a745;
}
</style>
