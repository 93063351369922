<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>

    <!-- POPUP -->
    <div>
      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        :title="
          String(params.data.approver_id) == '0'
            ? `Reject Expense`
            : `Reject PO`
        "
        class="assigneePopup"
        :active.sync="popupActive1"
      >
        <div class="">
          <h5>Reason for Rejection</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-textarea
                counter="50"
                v-model="rejectionComment"
                v-validate="{ regex: /^[a-zA-Z ]+$/ }"
                @input.prevent="preventEnterKey"
                class="mb-4"
                rows="4"
              />
              <!-- :disabled="submitStatus" -->

              <div class="my-4">
                <h5>Email Addresses</h5>

                <multiselect
                  v-model="emailValue"
                  tag-placeholder="Add this as new Email"
                  placeholder="Search or add a Email"
                  label="user_email"
                  track-by="user_id"
                  :options="usersList"
                  :multiple="true"
                  :taggable="true"
                  @tag="addTag"
                  open-direction="bottom"
                ></multiselect>
              </div>

              <div class="text-center">
                <vs-button
                  color="success"
                  type="filled"
                  @click="onSubmit()"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button color="danger" type="filled" @click="privStatus()"
                  >Cancel</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive2"
      >
        <div class="text-center">
          <h5>Reason For Accept (Exceeded Budget) PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive3"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Accept Expense
          </h5>
          <h5 v-else>Reason For Accept PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive5"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Resubmitting
          </h5>
          <h5 v-else>Reason For Resubmitting</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="submitForm('resubmit=1')"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Hold Expense"
        :active.sync="popupActive4"
      >
        <div class="text-center">
          <h5>Reason For Hold Expense</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="holdReason" class="mb-4" rows="4" />
              <vs-button
                color="success"
                type="filled"
                @click="onHoldSubmit()"
                class="mr-3"
                >Submit</vs-button
              >
              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        fullscreen
        :id="'popmodale' + params.data.purchase_id"
        :title="
          String(params.data.approver_id) == '0'
            ? `Edit Expense`
            : params.data.request_type == 'CASH REQUEST'
            ? 'Edit Cash Request'
            : `Edit PO`
        "
        class="po-popup"
        :active.sync="popupActive"
      >
        <div class="row mx-0 pt-5 h-100">
          <div class="col-md-6">
            <div class="mb-5 p-3">
              <!-- MODERATOR -->
              <ul
                class="level-list mb-5"
                v-if="is_paid != 1 && mod_user.length > 0"
              >
                <li class="pb-2">
                  <h5>
                    <img
                      src="@/assets/images/icons/moderator.svg"
                      height="20"
                      width="20"
                      style="position: relative; top: -3px"
                    />
                    Production Accountant
                  </h5>

                  <div v-for="(mod_user, index) in mod_user" :key="index">
                    <!-- PENDING FROM MODERATOR -->
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="mod_user.purchase_map_status == 0"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-pending.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            Approval pending from&nbsp;
                            <span class="font-weight-bold">
                              {{ mod_user.user_name }}
                            </span>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                (LoginUserId == mod_user.user_id ||
                                  adminManagerArray.filter((u) => u == UserID)
                                    .length > 0) &&
                                purchaseMapDetails.userOnlyView == 0
                              "
                              v-on:click="approvePO(mod_user, 'moderator')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                HoldOrg == 1 &&
                                (LoginUserId == mod_user.user_id ||
                                  adminManagerArray.filter((u) => u == UserID)
                                    .length > 0) &&
                                purchaseMapDetails.userOnlyView == 0
                              "
                              v-on:click="holdPO(mod_user, 'moderator')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="PauseCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                (LoginUserId == mod_user.user_id ||
                                  adminManagerArray.filter((u) => u == UserID)
                                    .length > 0) &&
                                purchaseMapDetails.userOnlyView == 0
                              "
                              v-on:click="rejectPO(mod_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- APPROVED MODERATOR -->
                    <div class="py-2" v-if="mod_user.purchase_map_status == 1">
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approved-tick.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <span>
                            {{ mod_user.description }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="mod_user.reason != '' && mod_user.reason != '-'"
                      >
                        Reason: {{ mod_user.reason }}
                      </div>
                    </div>

                    <!-- HELD MODERATOR -->
                    <div class="py-2" v-if="mod_user.purchase_map_status == 7">
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/video-pause-button.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            Approval held by&nbsp;
                            <span class="font-weight-bold">
                              {{ mod_user.user_name }}
                            </span>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                (LoginUserId == mod_user.user_id ||
                                  adminManagerArray.filter((u) => u == UserID)
                                    .length > 0) &&
                                purchaseMapDetails.userOnlyView == 0
                              "
                              v-on:click="approvePO(mod_user, 'moderator')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>

                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                (LoginUserId == mod_user.user_id ||
                                  adminManagerArray.filter((u) => u == UserID)
                                    .length > 0) &&
                                purchaseMapDetails.userOnlyView == 0
                              "
                              v-on:click="rejectPO(mod_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="mod_user.reason != '' && mod_user.reason != '-'"
                      >
                        Reason: {{ mod_user.reason }}
                      </div>
                    </div>

                    <!-- REJECTED BY MODERATOR -->
                    <div class="py-2" v-if="mod_user.purchase_map_status == 2">
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-rejected.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <span>
                            {{ mod_user.description }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="mod_user.reason != '' && mod_user.reason != '-'"
                      >
                        Reason: {{ mod_user.reason }}
                      </div>
                    </div>

                    <!-- NOT YET REACHED TO MODERATOR -->
                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="mod_user.purchase_map_status == 3"
                        class="d-flex py-2"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="mod_user.user_id != mod_user.puserid"
                          />
                        </div>
                        <span>
                          {{ mod_user.description }}
                        </span>
                      </div>
                    </div>
                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="mod_user.purchase_map_status == 4"
                        class="d-flex py-2"
                      >
                        <!-- <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>-->
                        {{ mod_user.approvedBy }} for&nbsp;
                        <span class="font-weight-bold">
                          {{ mod_user.user_name }}!
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- USER -->
              <ul
                class="level-list mb-5"
                v-if="is_paid != 1 || (is_paid == 1 && admin_user.length == 0)"
              >
                <li v-for="(level, levelIndex) in levels" :key="levelIndex">
                  <h5>
                    <!-- <img src="@/assets/images/icons/level2.svg" height="20" width="20" alt /> -->
                    Level {{ level.levelName }}
                  </h5>
                  <div class="col-11 ml-auto px-0">
                    <!-- LOOP THIS -->
                    <div
                      v-for="(singleUser, userIndex) in level.level"
                      :key="'user' + userIndex"
                      class="d-flex align-items-center py-2 border-bottom"
                    >
                      <!-- APPROVED USER-->
                      <div
                        v-if="
                          singleUser.purchase_map_status == 1 ||
                          singleUser.purchase_map_status == 6
                        "
                      >
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approved-tick.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <span>
                            {{ singleUser.description }}
                          </span>
                        </div>

                        <div
                          class="mt-2 pl-12"
                          v-if="
                            singleUser.reason != '' && singleUser.reason != '-'
                          "
                        >
                          Reason: {{ singleUser.reason }}
                        </div>
                      </div>

                      <!-- HELD USER -->
                      <div v-if="singleUser.purchase_map_status == 7">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/video-pause-button.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <span>
                            {{ singleUser.description }}
                          </span>

                          <!-- <span class="font-weight-bold">

                        </span> -->
                          <div
                            v-if="
                              UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                adminManagerArray.filter((u) => u == UserID)
                                  .length > 0 ||
                                (singleUser.user_level > userLevelLogin &&
                                  UserRole.includes('User'))) &&
                              purchaseMapDetails.userOnlyView == 0
                            "
                            v-on:click="approvePO(singleUser, 'user')"
                            class="ml-2"
                          >
                            <feather-icon
                              icon="CheckCircleIcon"
                              class="cursor-pointer text-success"
                              svgClasses="h-8 w-8"
                            />
                          </div>

                          <div
                            v-if="
                              UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                adminManagerArray.filter((u) => u == UserID)
                                  .length > 0) &&
                              purchaseMapDetails.userOnlyView == 0
                            "
                            v-on:click="rejectPO(singleUser)"
                            class="ml-2"
                          >
                            <feather-icon
                              icon="XCircleIcon"
                              class="cursor-pointer text-danger"
                              svgClasses="h-8 w-8"
                            />
                          </div>
                        </div>

                        <div
                          class="mt-2 pl-12"
                          v-if="
                            singleUser.reason != '' && singleUser.reason != '-'
                          "
                        >
                          Reason: {{ singleUser.reason }}
                        </div>
                      </div>

                      <!-- REJECTED USER -->
                      <div v-if="singleUser.purchase_map_status == 2">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-rejected.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <span>
                            {{ singleUser.description }}
                          </span>
                        </div>

                        <div
                          class="mt-2 ml-12"
                          v-if="
                            singleUser.reason != '' && singleUser.reason != '-'
                          "
                        >
                          <div>
                            <span class="font-weight-bold">Reason:</span>
                            {{ singleUser.reason }}
                          </div>
                          <div v-if="singleUser.reject_emails">
                            <span class="font-weight-bold"
                              >Email Notification:</span
                            >
                            {{ singleUser.reject_emails }}
                          </div>
                        </div>
                      </div>

                      <!-- PENDING USER -->
                      <div
                        v-if="singleUser.purchase_map_status == 0"
                        class="d-flex align-items-center"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>
                        <span>
                          {{ singleUser.description }}
                        </span>

                        <!-- <span class="font-weight-bold">

                        </span> -->
                        <div
                          v-if="
                            UserRole != 'Admin' &&
                            (LoginUserId == singleUser.user_id ||
                              adminManagerArray.filter((u) => u == UserID)
                                .length > 0 ||
                              (singleUser.user_level > userLevelLogin &&
                                UserRole.includes('User'))) &&
                            purchaseMapDetails.userOnlyView == 0
                          "
                          v-on:click="approvePO(singleUser, 'user')"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            class="cursor-pointer text-success"
                            svgClasses="h-8 w-8"
                          />
                        </div>
                        <div
                          v-if="
                            UserRole != 'Admin' &&
                            HoldOrg == 1 &&
                            (LoginUserId == singleUser.user_id ||
                              adminManagerArray.filter((u) => u == UserID)
                                .length > 0) &&
                            purchaseMapDetails.userOnlyView == 0
                          "
                          v-on:click="holdPO(singleUser, 'user')"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="PauseCircleIcon"
                            class="cursor-pointer"
                            svgClasses="h-8 w-8"
                          />
                        </div>
                        <div
                          v-if="
                            UserRole != 'Admin' &&
                            (LoginUserId == singleUser.user_id ||
                              adminManagerArray.filter((u) => u == UserID)
                                .length > 0) &&
                            purchaseMapDetails.userOnlyView == 0
                          "
                          v-on:click="rejectPO(singleUser)"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            class="cursor-pointer text-danger"
                            svgClasses="h-8 w-8"
                          />
                        </div>
                      </div>

                      <!-- NOT YET REACHED TO USER -->
                      <div
                        v-if="singleUser.purchase_map_status == 3"
                        class="d-flex"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="singleUser.user_id != singleUser.puserid"
                          />
                        </div>

                        <span>
                          {{ singleUser.description }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- ADMIN -->
              <ul
                class="level-list mb-5"
                v-if="is_paid == 1 && admin_user.length > 0"
              >
                <li class="pb-2">
                  <h5>
                    <img
                      src="@/assets/images/icons/moderator.svg"
                      height="20"
                      width="20"
                      style="position: relative; top: -3px"
                    />
                    Admin Manager
                  </h5>

                  <div v-for="(admin_user, index1) in admin_user" :key="index1">
                    <!-- PENDING FROM ADMIN -->
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="admin_user.purchase_map_status == 0"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-pending.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            Approval pending from&nbsp;
                            <span class="font-weight-bold">
                              {{ admin_user.user_name }}
                            </span>
                            <div
                              v-if="
                                UserRole == 'Admin Manager' ||
                                LoginUserId == admin_user.user_id
                              "
                              v-on:click="approvePO(admin_user, 'admin')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                HoldOrg == 1 &&
                                (UserRole == 'Admin Manager' ||
                                  LoginUserId == admin_user.user_id)
                              "
                              v-on:click="holdPO(admin_user, 'admin')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="PauseCircleIcon"
                                class="cursor-pointer"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                UserRole == 'Admin Manager' ||
                                LoginUserId == admin_user.user_id
                              "
                              v-on:click="rejectPO(admin_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- APPROVED ADMIN -->
                    <div
                      class="py-2"
                      v-if="admin_user.purchase_map_status == 1"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approved-tick.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <span>
                            {{ admin_user.description }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="
                          admin_user.reason != '' && admin_user.reason != '-'
                        "
                      >
                        Reason: {{ admin_user.reason }}
                      </div>
                    </div>

                    <!-- HELD ADMIN -->
                    <div
                      class="py-2"
                      v-if="admin_user.purchase_map_status == 7"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/video-pause-button.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            <span class="font-weight-bold">
                              {{ admin_user.description }}
                            </span>
                            <div
                              v-if="
                                UserRole == 'Admin Manager' ||
                                LoginUserId == admin_user.user_id
                              "
                              v-on:click="approvePO(admin_user, 'admin')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>

                            <div
                              v-if="
                                UserRole == 'Admin Manager' ||
                                LoginUserId == admin_user.user_id
                              "
                              v-on:click="rejectPO(admin_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="
                          admin_user.reason != '' && admin_user.reason != '-'
                        "
                      >
                        Reason: {{ admin_user.reason }}
                      </div>
                    </div>

                    <!-- REJECTED BY ADMIN -->
                    <div
                      class="py-2"
                      v-if="admin_user.purchase_map_status == 2"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-rejected.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <span>
                            {{ admin_user.description }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="mt-2 pl-12"
                        v-if="
                          admin_user.reason != '' && admin_user.reason != '-'
                        "
                      >
                        Reason: {{ admin_user.reason }}
                      </div>
                    </div>

                    <!-- NOT YET REACHED TO ADMIN -->
                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="admin_user.purchase_map_status == 3"
                        class="d-flex py-2"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="admin_user.user_id != admin_user.puserid"
                          />
                        </div>
                        <span>
                          {{ admin_user.description }}
                        </span>
                      </div>
                    </div>

                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="admin_user.purchase_map_status == 4"
                        class="d-flex py-2"
                      >
                        <!-- <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>-->
                        {{ admin_user.approvedBy }} for&nbsp;
                        <span class="font-weight-bold">
                          {{ admin_user.user_name }}!
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div
              v-if="
                (purchase_status == 7 || purchase_status == 2) &&
                purchaseData.request_type == 'EXPENSE' &&
                isHoldOrg == 1
              "
            >
              <div
                class="flex flex-wrap justify-between items-center form-title"
              >
                <h4 class>Comment Section</h4>
              </div>
              <div
                class="flex flex-wrap justify-between items-center form-title"
              >
                <h4 class>Add Comments</h4>
              </div>
              <div class="vx-col w-full px-5 mb-5 formCommentBox">
                <vs-input
                  class="inputx w-full"
                  placeholder="Type Something"
                  v-model="comment_message"
                />
                <span class="commentSendIcon" @click="addComment">
                  <img src="@/assets/images/icons/send-icon.svg" alt />
                </span>
              </div>
              <div class="mb-6" v-if="commentsList.length > 0">
                <div
                  class="flex flex-wrap justify-between items-center form-title"
                >
                  <h4 class>Comments</h4>
                </div>
                <div class="vx-col w-full">
                  <div
                    class="px-5 pb-4 mb-3 border-bottom w-full"
                    v-for="(commentRow, commentListIndex) in commentsList"
                    :key="commentListIndex"
                  >
                    <div class="vx-col w-full">
                      <h4 class="font-weight-bold">
                        {{
                          commentRow.is_reject == 0
                            ? "HOLD By " + commentRow.hold_creator
                            : "REJECT By " + commentRow.hold_creator
                        }}
                      </h4>
                      <!-- <span>{{commentRow.hold_creator}}</span> -->
                    </div>
                    <div
                      class="vx-col w-full"
                      v-for="(comment, commentIndex) in commentRow.comments"
                      :key="commentIndex"
                    >
                      <div class="mr-4">
                        <!-- <img
                                      v-if="commentRow.user_pic"
                                      :src="
                                        commentRow.user_pic +
                                        '?token=' +
                                        tempToken
                                      "
                                      height="50"
                                      width="50"
                                      class="comment-user-icon"
                                      alt
                                    /> -->
                        <!-- v-else -->
                        <img
                          src="@/assets/images/icons/user-icon.png"
                          height="50"
                          width="50"
                          class="comment-user-icon"
                          alt
                        />
                      </div>
                      <div class="w-100">
                        <h5 class="font-weight-bold">
                          {{ comment.comment_user }}
                        </h5>
                        <p class="text-dark">
                          <!-- <b>Comment:</b>  -->
                          {{ comment.comment }}
                        </p>
                        <div>
                          <span class="text-muted mr-4">
                            <!-- <b>Date and Time:</b>  -->
                            {{ comment.comment_created_date }}
                          </span>
                          <!-- @click="
                                          openReplyBox(
                                            commentRow,
                                            commentListIndex
                                          )
                                        " -->
                          <!-- <span class="text-primary font-bold cursor-pointer"
                            >Reply
                          </span> -->
                        </div>
                        <!-- REPLY INPUT COMMENT BOX -->
                        <!-- :class="
                                        commentRow.isShowReplyInput === true
                                          ? 'collapsed'
                                          : ''
                                      " -->
                        <!-- <div
                          class="
                            vx-col
                            w-75
                            formCommentBox
                            replyCommentBox
                            collapsible
                          "
                        > -->
                        <!-- <div class="my-5"> -->
                        <!-- v-model="commentRow.new_reply" -->
                        <!-- <vs-input
                              class="inputx w-full"
                              placeholder="Type Something"
                            /> -->
                        <!-- @click="
                                            sendCommentReply(
                                              commentRow,
                                              commentListIndex
                                            )
                                          " -->
                        <!-- <span class="commentSendIcon">
                              <img
                                src="@/assets/images/icons/send-icon.svg"
                                alt
                              />
                            </span> -->
                        <!-- </div> -->
                        <!-- </div> -->

                        <!-- SHOW COMMENTS -->
                        <!-- @click="showAllComments(commentListIndex)"
                                      v-if="
                                        commentRow.Reply.length > 0 &&
                                        !commentRow.showAllReply
                                      " -->
                        <!-- <p
                          class="
                            text-primary
                            font-bold
                            cursor-pointer
                            mt-1
                            mb-4
                          "
                        >
                          See all
                          {{ commentRow.Reply.length }}
                          comments
                        </p> -->

                        <!-- HIDE COMMENTS -->
                        <!-- @click="showAllComments(commentListIndex)"
                                      v-if="
                                        commentRow.Reply.length > 0 &&
                                        commentRow.showAllReply
                                      " -->
                        <!-- <p
                          class="
                            text-primary
                            font-bold
                            cursor-pointer
                            mt-1
                            mb-4
                          "
                        >
                          Hide comments
                        </p> -->

                        <!-- REPLY COMMENTS -->
                        <!-- v-if="commentRow.showAllReply" -->
                        <!-- <div class="vx-col w-full"> -->
                        <!-- v-for="(
                                          replayRow, replayCommentListIndex
                                        ) in commentRow.Reply"
                                        :key="replayCommentListIndex" -->
                        <!-- <div class="d-flex px-5 pb-4 mb-3"> -->
                        <!-- <div class="mr-4"> -->
                        <!-- <img
                                            v-if="replayRow.user_pic"
                                            :src="
                                              replayRow.user_pic +
                                              '?token=' +
                                              tempToken
                                            "
                                            height="50"
                                            width="50"
                                            class="comment-user-icon"
                                            alt
                                          /> -->
                        <!-- v-else -->
                        <!-- <img
                                src="@/assets/images/icons/user-icon.png"
                                height="50"
                                width="50"
                                class="comment-user-icon"
                                alt
                              /> -->
                        <!-- </div> -->
                        <!-- <div class="w-100"> -->
                        <!-- <h5 class="font-weight-bold">
                                {{ replayRow.user_name }}
                              </h5>
                              <p class="text-dark">
                                {{ replayRow.mou_reply_comment }}
                              </p> -->
                        <!-- <div> -->
                        <!-- <span class="text-muted mr-4">
                                  {{
                                                replayRow.mou_reply_created_date
                                              }}
                                </span> -->
                        <!-- @click="
                                                openReplyToReplyBox(
                                                  replayRow,
                                                  commentListIndex,
                                                  replayCommentListIndex
                                                )
                                              " -->
                        <!-- <span
                                  class="text-primary font-bold cursor-pointer"
                                  >Reply</span
                                > -->
                        <!-- </div> -->
                        <!-- REPLY COMMENT BOX -->
                        <!-- :class="
                                              replayRow.isShowReplyInput ===
                                              true
                                                ? 'collapsed'
                                                : ''
                                            " -->
                        <!-- <div
                                class="
                                  vx-col
                                  w-75
                                  formCommentBox
                                  replyCommentBox
                                  collapsible
                                "
                              > -->
                        <!-- <div class="my-4"> -->
                        <!-- v-model="replayRow.new_reply" -->
                        <!-- <vs-input
                                    class="inputx w-full"
                                    placeholder="Type Something"
                                  /> -->
                        <!-- @click="
                                                  sendCommentToReply(
                                                    replayRow,
                                                    commentListIndex,
                                                    replayCommentListIndex
                                                  )
                                                " -->
                        <!-- <span class="commentSendIcon">
                                    <img
                                      src="@/assets/images/icons/send-icon.svg"
                                      alt
                                    />
                                  </span> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <form ref="testingRef">
              <FocusLock>
                <div class="vx-row border-left">
                  <!-- LEFT SIDE -->
                  <div class="vx-col w-full">
                    <h2
                      class="text-center mb-base"
                      v-html="
                        String(params.data.approver_id) == '0'
                          ? `EDIT EXPENSE`
                          : params.data.request_type == 'CASH REQUEST'
                          ? `EDIT CASH REQUEST`
                          : `EDIT PURCHASE ORDER`
                      "
                    ></h2>
                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5
                          class="mb-0"
                          v-html="
                            String(params.data.approver_id) == '0'
                              ? `Expense Name`
                              : `PO Name`
                          "
                        ></h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            v-validate="'required'"
                            name="PO/Expense Name"
                            v-model="purchaseData.po_name"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('PO/Expense Name')"
                            >{{ errors.first("PO/Expense Name") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">User</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            v-validate="'required'"
                            name="User Name"
                            v-model="purchaseData.user_name"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('User Name')"
                            >{{ errors.first("User Name") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Project</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <!-- <vs-input
                            v-model="purchaseData.project_name"
                            class="mb-0"
                            rows="4"
                            v-validate="'required'"
                          />-->
                          <multiselect
                            v-model="project_value"
                            v-validate="'required'"
                            track-by="project_id"
                            label="project_name"
                            placeholder="Project Name"
                            @input="
                              ({ project_id }) => (this.project_id = project_id)
                            "
                            :options="allProjects"
                            name="Project Name"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            disabled
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.project_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('Project Name')"
                              >{{ errors.first("Project Name") }}</span
                            >
                          </p>
                          <!-- <vs-select
                            v-validate="'required'"
                            v-model="purchaseData.project_name"
                            name="Project Name"
                            class="select-large w-full"
                          >
                            <vs-select-item
                              :key="index"
                              :value="item.project_id"
                              :text="item.project_name"
                              v-for="(item,index) in allProjects"
                            />
                          </vs-select>
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Project Name')"
                          >{{ errors.first('Project Name') }}</span>-->
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-6"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">VHC Code</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            v-validate="'required'"
                            name="VHC Code"
                            v-model="purchaseData.VHC_code"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('VHC Code')"
                            >{{ errors.first("VHC Code") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Category</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <!-- <multiselect
                            :options="allCategories"
                            v-model="category_value"
                            v-validate="'required'"
                            track-by="category_id"
                            label="category_name"
                            placeholder="Category Name"
                            @input="({category_id})=> this.category_id = category_id"
                            name="Category Name"
                            :searchable="false"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 1 || purchase_status == 2 "
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >{{ option.category_name }}</template>
                          </multiselect>-->
                          <multiselect
                            v-model="categoryValue"
                            track-by="category_id"
                            label="category_name"
                            @input="
                              ({ category_id }) =>
                                (this.form.parent_id = category_id)
                            "
                            :options="categories"
                            name="Category Name"
                            placeholder="Select Category"
                            @select="onSuggestSelect"
                            :searchable="true"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 2"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.category_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('Category Name')"
                              >{{ errors.first("Category Name") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Subcategory</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <!-- <multiselect
                            v-model="subcategoy_value"
                            v-validate="'required'"
                            track-by="category_id"
                            label="category_name"
                            placeholder="Sub Category"
                            :options="sub_categories"
                            name="Sub Category"
                            :searchable="false"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 1"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >{{ option.sub_category_name }}</template>
                          </multiselect>-->
                          <multiselect
                            v-model="subcategoryValue"
                            track-by="category_id"
                            @input="
                              ({ category_id }) =>
                                (this.form.category_id = category_id)
                            "
                            label="category_name"
                            :options="subcategories"
                            name="subcategory Name"
                            placeholder="Select Subcategory"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 2"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.category_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('subcategory Name')"
                              >{{ errors.first("subcategory Name") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-1"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Country</h5>
                      </div>

                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            :options="countries"
                            v-model="country_value"
                            v-validate="'required'"
                            track-by="country_id"
                            label="country_name"
                            placeholder="Country Name"
                            @input="
                              ({ country_id }) => (this.country_id = country_id)
                            "
                            name="Country Name"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 2"
                            @select="onSelectCountry($event)"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.country_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('Country Name')"
                              >{{ errors.first("Country Name") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-1"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">City</h5>
                      </div>

                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            :options="cities"
                            v-model="city_value"
                            v-validate="'required'"
                            track-by="city_id"
                            label="city_name"
                            placeholder="City Name"
                            @input="({ city_id }) => (this.city_id = city_id)"
                            name="City Name"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 2"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.city_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('City Name')"
                              >{{ errors.first("City Name") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-3"
                      v-if="purchaseData.request_type != 'CASH'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Set Name</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            label="location_name"
                            class="w-full"
                            track-by="location_id"
                            v-model="locationValue"
                            name="location"
                            :options="shootLocationList"
                            :searchable="false"
                            :allow-empty="false"
                            open-direction="bottom"
                            placeholder="Select Option"
                            deselect-label="Can't remove this value"
                            :disabled="
                              purchase_status == 1 || purchase_status == 2
                            "
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.location_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('location')"
                              >{{ errors.first("location") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-6"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Invoice Number</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            name="invoice_number"
                            type="text"
                            v-model.number="purchaseData.invoice_number"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('invoice_number')"
                            >{{ errors.first("invoice_number") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="vx-row mb-6"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Invoice Date</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <datepicker
                            placeholder="To Date"
                            v-model="purchaseData.invoice_date"
                            format="dd-MM-yyyy"
                          ></datepicker>
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Invoice Date')"
                            >{{ errors.first("Invoice Date") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="vx-row mb-6"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Payment Due Date</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <datepicker
                            placeholder="To Date"
                            v-model="purchaseData.payment_due_date"
                            format="dd-MM-yyyy"
                          ></datepicker>
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Payment Due Date')"
                            >{{ errors.first("Payment Due Date") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            v-validate="'required'"
                            name="Amount"
                            type="number"
                            v-model.number="purchaseData.total"
                            @input="totalAmount"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Amount')"
                            >{{ errors.first("Amount") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="vx-row mb-3"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Tax</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            label="gst_name"
                            class="w-full"
                            track-by="gst_id"
                            v-model="purchaseData.gst_id"
                            name="gst"
                            @select="gstCalculate"
                            :options="gstList"
                            :searchable="false"
                            :allow-empty="false"
                            open-direction="bottom"
                            placeholder="Select Option"
                            deselect-label="Can't remove this value"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                              >{{ option.gst_name }}</template
                            >
                          </multiselect>
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Priority')"
                              >{{ errors.first("Priority") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-3"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">GST Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            :disabled="gst_name != 'Manual Amount'"
                            type="number"
                            name="GST"
                            v-model="purchaseData.gst_amount"
                            @input="totalAmount"
                            class="w-full"
                            placeholder=""
                          />
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('GST Amount')"
                              >{{ errors.first("GST Amount") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="vx-row mb-3"
                      v-if="purchaseData.request_type != 'CASH REQUEST'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">Total Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            type="number"
                            name="Total Amount"
                            v-model="purchaseData.total_amount"
                            class="w-full"
                            placeholder=""
                          />
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Total Amount')"
                              >{{ errors.first("Total Amount") }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h5 class="mb-0">GST Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            name="gst_amount"
                            type="number"
                            v-model.number="purchaseData.gst_amount"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('GST Amount')"
                            >{{ errors.first("GST Amount") }}</span
                          >
                        </div>
                      </div>
                    </div> -->

                    <div class="vx-row mb-6">
                      <div class="vx-col sm:w-1/4 w-full text-right">
                        <h5 class="mb-0">Description</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vs-textarea
                          v-model="purchaseData.purchase_description"
                          class="mb-0"
                          rows="4"
                        />
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('Description')"
                          >{{ errors.first("Description") }}</span
                        >
                      </div>
                    </div>

                    <div
                      v-if="
                        (purchase_status == 1 || purchase_status == 2) &&
                        String(params.data.approver_id) == '0'
                      "
                      class="vx-row mb-6 doc-upload"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-right align-self-center"
                      >
                        <h6>Upload Document</h6>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vue-dropzone
                          @vdropzone-removed-file="vremoved"
                          @vdropzone-error-multiple="vdropzoneError"
                          @vdropzone-complete-multiple="vdropzoneComplete"
                          @vdropzone-added-files="vdropzoneFilesAdded"
                          @vdropzone-success-multiple="vdropzoneSuccessFile"
                          ref="myVueDropzoneFile"
                          id="dropzone"
                          class="rounded"
                          :options="dropzoneOptionsFiles"
                        ></vue-dropzone>
                        <!-- v-if="fileUpload.deleteButtonStatus"
                        @click.prevent="clearFile()" -->
                        <vs-button
                          type="filled"
                          class="feather icon-trash mt-2"
                          @click.prevent="clearFile()"
                        ></vs-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col mx-auto">
                    <vs-button
                      v-if="
                        file_upload &&
                        purchase_status == 1 &&
                        onlyView == 0 &&
                        String(params.data.approver_id) == '0'
                      "
                      type="filled"
                      @click.prevent.native="resubmitconformation()"
                      class="mr-3"
                    >
                      Update Documents
                    </vs-button>
                    <!-- <vs-button v-if="showlog" @click.prevent.native="showLogs()"
                      >View Log</vs-button
                    > -->

                    <vs-button
                      v-else-if="
                        showresubmit &&
                        String(params.data.approver_id) == '0' &&
                        UserRole != 'Admin' &&
                        purchaseMapDetails.userOnlyView == 0
                      "
                      type="filled"
                      @click.prevent.native="resubmitconformation()"
                      class="mr-3"
                    >
                      Update and Resubmit for Approval
                    </vs-button>

                    <vs-button
                      v-else
                      :disabled="
                        purchase_status != 0 ||
                        !canEdit ||
                        purchaseMapDetails.userOnlyView == 1 ||
                        form.category_id == null ||
                        form.category_id == '' ||
                        form.category_id == 0
                      "
                      type="filled"
                      @click.prevent.native="submitForm('resubmit=0')"
                      class="mr-3"
                    >
                      Update
                    </vs-button>
                  </div>
                </div>
              </FocusLock>
            </form>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive5"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Resubmitting
          </h5>
          <h5 v-else>Reason For Resubmitting</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="submitForm('resubmit=1')"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- <vs-popup class="holamundo" title="Lorem ipsum dolor sit amet" :active.sync="Testpopup">
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </vs-popup>-->
  </div>
</template>

<script>
import Vue from "vue";
import vue2Dropzone from "vue2-dropzone";
import constant from "@/helper/constant.js";
import { BASEURL } from "@/config/index";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import departmentService from "@/services/departmentService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import UserService from "@/services/UserService.js";
import projectService from "@/services/projectService.js";
import CategoryService from "@/services/categoryService.js";
import CountryService from "@/services/countryService.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import MethodService from "@/services/methodService.js";
import ShootLocationService from "../../../services/shootLocationService";
// import { BASEURL } from "@/config/index";
// import constant from "@/helper/constant.js";
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
//
import _ from "lodash";
import TreeVue from "../../components/extra-components/tree/Tree.vue";

export default Vue.extend({
  data() {
    return {
      isHoldOrg: window.localStorage.getItem("isHoldOrg"),
      uploadedImage: "",
      file_upload: true,
      onlyView: 0,
      uploadedDocument: "",
      uploadedDocumentArray: [],
      // dropzoneOptionsFiles: {
      //   url:  BASEURL() + "/v2/uploads",
      //   ...constant.DEFAULT_FILES,
      //   acceptedFiles: ".pdf,.xls,.xlsx,.csv,.img,.jpg,.webp",
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      showresubmit: false,
      usersList: [],
      emailValue: [],
      gstList: [],
      mod_user: [],
      LoginUserId: "",
      userLevelLogin: "",
      admin_user: [],
      purchaseData: {},
      rejectionComment: "",
      acceptComment: "",
      popupActive: false,
      price: null,
      is_paid: null,
      tableRefresData: {},
      popupActive1: false,
      popupActive2: false,
      popupActive3: false,
      popupActive4: false,
      popupActive5: false,
      submitStatus: false,
      params: null,
      value: null,
      count: 0,
      user_id: "",
      purchase_status: "",
      map_id: "",
      pmap_id: "",
      project_id: "",
      purchase_id: "",
      form: {
        parent_id: "",
        category_id: "",
      },
      isPopup: null,
      POuser: "",
      acceptReason: "",
      levels: [],
      countries: [],
      cities: [],
      country_value: [],
      city_value: [],
      project_value: [],
      allProjects: [],
      // allCategories: [],
      // sub_categories: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null,
      gst_name: "",
      gst_percentage: 0,
      // Testpopup: false
      comment_message: "",
      commentsList: [],
      holdReason: "",
      canEdit: false,
      adminManagerArray: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
        //chunking: true,
      },
      // uploadedDocument: "-",
      // uploadedDocumentArray: [],
      selectedFilesUUID: [],
      // fileUpload: {
      //   file_url: "",
      //   deleteButtonStatus: false,
      // },
      shootLocationList: [],
      locationValue: [],
    };
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
  },
  watch: {
    popupActive: function (val) {
      if (this.popupActive == false) {
        setTimeout(() => {
          eventBus.$emit("refreshPOList", this.tableRefresData);
        }, 0.5);
      }
    },
  },
  beforeMount() {
    this.purchase_status = this.params.data.purchase_status;
    this.form = this.params.data;
    this.proj_id = this.params.data.project_id;
    this.isPopup = this.params.data.isPopup;
    this.is_paid = this.params.data.is_already_paid;
    this.getShootLocationList();
    // this.getMasterList();
    // this.getAllComments();
    // this.UserRole = window.localStorage.getItem("UserRole");
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.purchase_id ||
        "popmodalr" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    },
    UserID() {
      return localStorage.getItem("UserID");
    },
    HoldOrg() {
      return localStorage.getItem("isHoldOrg") || 0;
    },
  },
  methods: {
    preventEnterKey: function (e) {
      if (e.keyCode === 13 || e.which === 13) {
        return true;
      }
      return false;
    },
    getShootLocationList: function () {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == false) {
          } else {
            this.shootLocationList = data.data;
            this.shootLocationList.map((x) => {
              if (this.params.data.location_id == x.location_id) {
                this.locationValue = x;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearFile: function () {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
    },
    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },

    vdropzoneSuccessFile: function (file, response) {
      // this.user_agreement = response.data.image_path;

      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      console.log(this.uploadedDocument, "te");

      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
    },

    vdropzoneSuccess: function (file, response) {
      this.user_pic = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    resubmitconformation() {
      this.popupActive5 = true;
    },
    resubmitconformation1() {
      if (this.file_upload && this.purchase_status == 1) {
        this.popupActive44 = true;
      } else {
        this.submitForm("resubmit=0");
      }
    },
    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.gstList = data.data.gst;
            let Arry = this.gstList.filter((x) => {
              return x.gst_id == this.purchaseData.gst_id;
            });
            if (Arry.length > 0) {
              let obj = Arry[0];

              this.purchaseData.gst_id = obj;
            }

            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gstCalculate(selected) {
      this.gst_name = selected.gst_name;
      this.gst_percentage = selected.gst_percentage;
      this.purchaseData.gst_amount = 0;

      let amount =
        this.purchaseData.total > 0
          ? parseFloat(this.purchaseData.total).toFixed(2)
          : 0;

      let totalAmount =
        (parseFloat(selected.gst_percentage).toFixed(2) * amount) / 100;
      this.purchaseData.gst_amount = parseFloat(totalAmount).toFixed(2);
      this.totalAmount();
    },
    totalAmount() {
      let amount =
        this.purchaseData.total != ""
          ? parseFloat(this.purchaseData.total).toFixed(2)
          : 0;

      this.purchaseData.total_amount =
        (this.purchaseData.gst_amount
          ? parseFloat(this.purchaseData.gst_amount)
          : 0) + parseFloat(amount);
    },
    showModal() {
      this.getMasterList();
      this.getAllComments();
      this.popupActive = true;
      this.getProjectList();
      this.getCategoryList();
      this.getCountryList();
      this.getCityList();
      // this.getSubCategoryList(this.purchaseData.category_id);
      this.getPurchaseMapDetails();
    },
    getPurchaseMapDetails() {
      purchaseOrderService
        .getPurchaseOrderMaping(this.purchase_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            console.log("getPurchaseMapDetails", data);

            this.levels = [];

            this.purchaseMapDetails = data;
            this.onlyView = data.userOnlyView;
            this.adminManagerArray = data.adminManagerArray;
            console.log(data, "data.data", data.showresubmit);
            this.showresubmit = data.showresubmit;
            this.showlog = data.showlog;
            this.editableProjects = data.editableProjects;

            if (this.editableProjects.length > 0) {
              if (
                this.editableProjects.filter((u) => u == this.proj_id).length >
                0
              ) {
                this.canEdit = true;
              } else {
                this.canEdit = false;
              }
            } else {
              this.canEdit = false;
            }

            //  MODERATOR
            let ModArray = data.data.filter(({ mod }) => String(mod) === "1");

            let otherArray = data.data
              .filter(({ mod, user_level }) => {
                console.log(String(mod), user_level);
                return (
                  String(mod) !== "1" &&
                  (String(mod) !== "0" || String(user_level) !== "0")
                );
              })
              .reverse();

            this.mod_user = ModArray.length > 0 ? ModArray : this.mod_user;

            //  ADMIN
            let AdminArray = data.data.filter(
              ({ mod, user_level }) => String(mod) === "2"
            );

            // let otherArrayNew = data.data
            //   .filter(({ mod, user_level }) => {
            //     console.log(
            //       ' String(mod) != "0" && String(user_level) != "0" :',
            //       String(mod) != "0",
            //       String(user_level) != "0"
            //     );
            //     return String(mod) != "0" || String(user_level) != "0";
            //   })
            //   .reverse();

            this.admin_user =
              AdminArray.length > 0 ? AdminArray : this.admin_user;
            console.log(data, "iuyiuyi");
            this.LoginUserId = data.loggedInUserId;
            this.userLevelLogin = data.user_level;
            // this.userLevelLogin = data.data.filter(x => {
            //   return x.user_id == data.user_id;
            // })[0]["user_level"];

            // response.data.data.map(user => {
            //   if (user.mod == 1) {
            //     this.mod_user = user;
            //   }
            //   if (user.mod == 0 && user.user_level == 0) {
            //     this.admin_user = user;
            //   }
            // });
            // let levelGroup = _.groupBy(response.data.data, "user_level");
            console.log(otherArray, "DGDSFGDS");
            let levelGroup = _.groupBy(otherArray, "user_level");

            console.log(
              _.groupBy(otherArray, "user_level"),
              levelGroup,
              "fsdfdsf"
            );

            // this.admin = levelGroup[0];
            // delete levelGroup[0];
            let newArr = [];
            let reverse = Object.keys(levelGroup).reverse();
            this.levels = [];
            reverse.map((x) => {
              console.log("x :", x);
              this.levels.push({
                levelName: x,
                level: levelGroup[parseInt(x)],
              });
            });

            console.log(this.levels);
          }
        })
        .catch((err) => {});
    },
    getProjectList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.allProjects = data.data;
            this.allProjects.map((x) => {
              if (this.params.data.project_id == x.project_id) {
                this.project_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            // text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    // getCategoryList: function() {
    //   let id = this.proj_id;
    //   CategoryService.getProjectMainCategories(id)
    //     .then(response => {
    //       const { data } = response;
    //       if (data.Status) {
    //         this.allCategories = data.data;
    //         this.allCategories.map(x => {
    //           if (this.params.data.category_id == x.category_id) {
    //             this.category_value = x;
    //           }
    //         });
    //       } else {
    //         this.$vs.notify({
    //           title: "Categories not found",
    //           // text: data.Message,
    //           iconPack: "feather",
    //           icon: "check_box",
    //           color: "warning"
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       this.$vs.notify({
    //         title: "Error!1",
    //         // text: data.Message,
    //         iconPack: "feather",
    //         icon: "check_box",
    //         color: "warning"
    //       });
    //     });
    // },
    getCategoryList: function () {
      let ID = this.proj_id;
      CategoryService.getProjectMainCategories(ID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
            this.categories.map((x) => {
              if (this.params.data.parent_id === x.category_id) {
                this.categoryValue = x;
                this.form.parent_id = x.category_id;
                this.getSubcategoryList(x.category_id);
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSuggestSelect: function (selected) {
      this.subcategoryValue = null;
      this.form.category_id = "";
      this.getSubcategoryList(selected.category_id);
    },
    getSubcategoryList: function (ID) {
      let PId = this.proj_id;
      CategoryService.getSubCategoriesByParentCategory(ID, PId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            this.subcategories.map((x) => {
              if (this.params.data.category_id === x.category_id) {
                this.subcategoryValue = x;
                this.form.category_id = x.category_id;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    onSelectCountry({ country_id }) {
      this.getCityList(country_id);
    },
    getCountryList: function () {
      CountryService.getAllCountry()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.countries = data.data;
            this.countries.map((x) => {
              if (this.params.data.country_id == x.country_id) {
                this.country_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {});
    },
    getCityList: function (id) {
      this.cities = [];
      this.city_value = null;
      this.city_id = null;
      CountryService.getCityByCountry(id ? id : this.params.data.country_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.cities = data.data;
            this.cities.map((x) => {
              if (this.params.data.city_id == x.city_id) {
                this.city_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {});
    },

    // getSubCategoryList: function(categoryID) {
    //   console.log("categoryID :", categoryID);
    //   let payload = {
    //     categoryID: categoryID
    //   };
    //   CategoryService.getSubCategoryByCategory(payload).then(response => {
    //     const data = response.data;
    //     if (!data.error && data) {
    //       this.sub_categories = data.data;
    //       this.sub_categories.map(x => {
    //         if (this.params.data.sub_category_id == x.sub_category_id) {
    //           this.subcategoy_value = x;
    //         }
    //       });
    //       // console.log("data :", data);
    //       // this.categories = data.data;
    //     } else {
    //       // Show Error Message, Whcih is comming from backend5
    //     }
    //   });
    // },
    submitForm: function (resubmit = "resubmit=0") {
      let bol = true;
      if (resubmit == "resubmit=1") {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          // this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          bol = false;
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      }
      if (bol) {
        this.$validator.validateAll().then((result) => {
          this.popupActive5 = false;
          if (result) {
            let payload = {
              purchase_image: this.uploadedDocument,
              reason: this.acceptReason,
              project_id: this.purchaseData.project_id,
              user_id: this.purchaseData.user_id,
              po_name: this.purchaseData.po_name,
              item_name: this.purchaseData.item_name,
              company_name: this.purchaseData.company_name,
              company_address: this.purchaseData.company_address,
              price: this.purchaseData.total,
              currency: this.purchaseData.currency,
              VHC_code: this.purchaseData.VHC_code,
              sgst: "1",
              cgst: "1",
              total: this.purchaseData.total,
              location_id: this.locationValue.location_id,
              // purchase_image: this.purchaseData.purchase_image,
              parent_id: this.form.parent_id,
              category_id: this.form.category_id,
              city_id: this.city_value.city_id,
              country_id: this.country_value.country_id,
              purchase_description: this.purchaseData.purchase_description,
              purchase_status: this.purchaseData.purchase_status,
              approved_percentage: this.purchaseData.approved_percentage,
              purchase_active: this.purchaseData.purchase_active,
              invoice_number: this.purchaseData.invoice_number,

              invoice_date: this.purchaseData.invoice_date
                ? moment(this.purchaseData.invoice_date).format("YYYY-MM-DD")
                : "",

              payment_due_date: this.purchaseData.payment_due_date
                ? moment(this.purchaseData.payment_due_date).format(
                    "YYYY-MM-DD"
                  )
                : "",
              gst_amount: this.purchaseData.gst_amount,
              gst_id: this.purchaseData.gst_id
                ? this.purchaseData.gst_id.gst_id
                : this.purchaseData.gst_id.gst_id,
              total_amount: this.purchaseData.total_amount,
              vendor_id: this.purchaseData.vendor_id,
            };
            console.log(payload);

            purchaseOrderService
              .editPurchaseOrder(payload, this.purchase_id, resubmit)
              .then((response) => {
                const { data } = response;
                if (data.Status == true) {
                  this.$vs.notify({
                    title: "Updated!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  eventBus.$emit("refreshPOList", data);
                  this.popupActive5 = false;
                } else {
                  this.$vs.notify({
                    title: "Error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                  this.popupActive5 = false;
                }
              })
              .catch((err) => {});
          }
        });
      }
    },
    approvePO: function (user, type) {
      // this.Testpopup = true;
      if (type == ("user", "admin") && this.isPopup == 1) {
        this.POuser = user;
        this.popupActive2 = true;
      } else {
        this.POuser = user;
        this.popupActive3 = true;
        // this.approvePurchaseOrder(user);
      }
    },
    holdPO: function (user, type) {
      this.user_id = user.user_id;
      this.purchase_id = user.purchase_id;
      this.project_id = user.project_id;
      this.popupActive4 = true;
    },
    getUserList: function () {
      let filter = {
        contracy_active: 1,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      UserService.UserList(filter)
        .then((response) => {
          const { data } = response;

          if (data.err || data.Status == false) {
          } else {
            this.usersList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onSubmitPO: function () {
      if (this.isPopup == 1) {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      } else {
        this.acceptReason = this.acceptComment;
        this.approvePurchaseOrder(this.POuser, this.acceptReason);
      }
    },

    approvePurchaseOrder: function (user, reason) {
      let obj = {
        project_id: user.project_id,
        purchase_id: this.purchase_id,
        user_id: user.user_id,
        reason: reason,
      };
      purchaseOrderService
        .approvePurchaseOrderDashboard(obj)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.getPurchaseMapDetails();
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.popupActive2 = false;
            this.popupActive3 = false;
            this.acceptComment = "";
            this.acceptReason = "";
            this.tableRefresData = data;
            // setTimeout(() => {
            //   eventBus.$emit("refreshPOList", data);
            // }, 1);
            // this.purchaseData = data.data;
          } else {
            this.$vs.notify({
              title: "Opps!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.popupActive2 = false;
            this.popupActive3 = false;
          }
        });
    },

    privStatus: function () {
      this.popupActive1 = false;
      this.popupActive2 = false;
      this.popupActive3 = false;
      this.popupActive4 = false;
      this.popupActive5 = false;
    },
    addTag(newTag) {
      console.log("newTag :>> ", newTag);
      const tag = {
        user_email: newTag,
        user_id: null,
      };
      this.usersList.push(tag);
      this.emailValue.push(tag);
    },
    onSubmit: function () {
      console.log("this.emailValue :>> ", this.emailValue);
      let Emails = [];
      if (this.emailValue.length > 0) {
        this.emailValue.map((x) => {
          Emails.push(x.user_email);
        });
      }
      if (this.rejectionComment.length > 0) {
        let obj = {
          project_id: this.project_id,
          purchase_id: this.purchase_id,
          user_id: this.user_id,
        };

        purchaseOrderService
          .rejectPurchaseOrderDashboard(obj, {
            reason: this.rejectionComment,
            user_emails: Emails,
          })
          .then((response) => {
            const { data } = response;
            if (data.Status == true) {
              this.popupActive1 = false;
              this.rejectionComment = "";
              this.user_id = "";
              this.map_id = "";
              this.emailValue = [];
              this.getPurchaseMapDetails();
              this.$vs.notify({
                title: "Rejected!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.tableRefresData = data;
              // setTimeout(() => {
              //   eventBus.$emit("refreshPOList", data);
              // }, 1);
              // this.$router.push({
              // path: "/user-po/?tabIndex=0"
              // });
              // this.purchaseData = data.data;
            } else {
              this.$vs.notify({
                title: "Opps!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
              this.popupActive1 = false;
            }
          });
      } else {
        this.$vs.notify({
          title: "Please provide a reason!",
          // text: data.error.message,
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        this.popupActive1 = false;
      }
    },
    onHoldSubmit: function () {
      let obj = {
        project_id: this.project_id,
        purchase_id: this.purchase_id,
        user_id: this.user_id,
        hold_reason: this.holdReason,
      };
      purchaseOrderService.holdPurchaseOrderDashboard(obj).then((response) => {
        const { data } = response;
        if (data.Status) {
          this.getPurchaseMapDetails();
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });

          this.popupActive4 = false;
          this.tableRefresData = data;
        } else {
          this.$vs.notify({
            title: "Opps!",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          this.popupActive4 = false;
        }
      });
    },
    rejectPO: function (user) {
      this.getUserList();
      this.user_id = user.user_id;
      this.purchase_id = user.purchase_id;
      this.project_id = user.project_id;
      this.popupActive1 = true;
    },
    addComment() {
      if (this.comment_message == "") {
        this.$vs.notify({
          title: "Field Required",
          text: "Type something in comment box",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }
      this.$vs.loading();
      let payload = {
        parent_comment_id: 0,
        comment: this.comment_message,
      };
      purchaseOrderService
        .addComment(this.purchase_id, payload)
        .then((response) => {
          const { data } = response;
          this.$vs.loading.close();
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.getAllComments();
            this.comment_message = "";
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getAllComments: function () {
      purchaseOrderService
        .getComments(this.purchase_id)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.commentsList = data.data;
          } else {
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Comments",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    vremoved: function (file) {
      // console.log("file, xhr, error :", file.upload);
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        //   this.$refs.myVueDropzoneFile.removeAllFiles();
        this.fileUpload.deleteButtonStatus = false;
        //   this.$refs.myVueDropzoneFile.enable();
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
      console.log(
        this.selectedFilesUUID,
        "==",
        this.uploadedDocumentArray,
        "==",
        this.uploadedDocument
      );
      this.file_upload = true;
    },
  },
  mounted() {
    this.uploadedDocumentArray = this.params.data.purchase_image.split(",");
    this.purchase_id = this.params.data.purchase_id;
    this.purchaseData = this.params.data;
  },
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}
</style>
