<!-- =========================================================================================
    File Name: projectPosList.vue
    Description: PURCHASE ORDER LIST
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard 
    Author: 
    Author URL: 
========================================================================================== -->


<template>
  <div>
    <!-- CHALLAN LIST -->

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>CHALLAN LIST</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="contacts"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
        ></ag-grid-vue>
        <!-- :pagination="true"
          :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"-->
        <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";

import editPoOrder from "./editPoOrder.vue";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd
  },
  data() {
    return {
      User: "Select User",
      popupActive: false,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" }
      ],
      columnDefs: [
        {
          headerName: "Name",
          field: "firstname",
          width: 200,
          filter: true
        },
        {
          headerName: "PO Number",
          field: "company",
          filter: true,
          width: 275
        },
        {
          headerName: "Department",
          field: "company",
          filter: true,
          width: 275
        },
        {
          headerName: "Category",
          field: "email",
          filter: true,
          width: 275
        },

        {
          headerName: "Country",
          field: "lastname",
          filter: true,
          width: 150
        },
        {
          headerName: "City",
          field: "city",
          filter: true,
          width: 150
        },
        {
          headerName: "Currency",
          field: "country",
          filter: true,
          width: 150
        },
        {
          headerName: "Amount",
          field: "country",
          filter: true,
          width: 150
        },
        {
          headerName: "Priority",
          field: "country",
          filter: true,
          width: 150
        },
        {
          headerName: "Status",
          field: "country",
          filter: true,
          width: 150
        },
        {
          headerName: "Description",
          field: "state",
          filter: true,
          width: 200
        },
        {
          headerName: "Multiple Images",
          field: "state",
          filter: true,
          width: 200
        }
      ],
      contacts: contacts
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>
.po-user-card {
  width: 130px;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>